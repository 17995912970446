@import './../../style/style-constants.module.scss';

.cards{

	margin-bottom: 12px;
	min-height: 183px;

	.card{
		display:inline-block;

		text-align:center;
		width:160px;
		height:180px;
		margin-right: 4px;
		&:last-child{margin-right:0px;}

		text-align:center;
		border-radius:2px;
		background-color: #F1F1EF;

		.name{
			font-size:12px;
			font-weight:700;
			margin:8px 0px 18px 0px;
			text-transform:capitalize;
		}

		.face{
			height:80px;
			margin: 0 auto;
			display:block;
			border-radius:40px;
		}


		.engagement-bar{
			text-align:left;
			margin-top:3px;
			height:12px;
			border-radius:4px;
			transition: all 0.2s ease-in-out;
		}
	}
}

.color-map{
	font-size: 12px;
	.rect-with-name{
		min-width: auto;
		margin-left: 20px;
		text-transform: capitalize;
	}
}

.face-small{
	height:60px;
	border-radius:30px;
}
