@import './../../style/style-constants.module.scss';

.channels{

	canvas{
		margin-top:-161px;
		margin-left: -70px;
		transform: scale(0.6)
	}

}
