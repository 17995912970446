@import './../../style/style-constants.module.scss';

@keyframes scale {
  0%   {transform:scale(0.5);}
  100%   {transform:scale(1);}
}

.bubbles{

	.candidate{
		border-spacing: 0px;
	}

	.info{padding-bottom: 6px;}

	.bubbles-cloud{
		vertical-align:middle;
		border-bottom: 1px solid $border-color;
		width: 90%;
		height:74px;

		text-align:right;
		word-wrap: break-word;

		&.last{border-bottom:0px;}
	}

	.face-small{
		vertical-align:top;
	}

	.phrase{
		display:inline-block;
		color: #76A2D9;
		margin-right:12px;
		animation: scale 0.4s linear 1;
	}
}
