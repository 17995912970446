//constants
$text-color: rgba(0,0,0,0.7);
$text-light-color: rgba(0,0,0,0.5);
$border-color: rgba(0,0,0,0.1);
$amobee-blue: #009ebd;
$white: #fff;
$line-light-color: rgba(0,0,0,0.1);
$line-dark-color:  rgba(0,0,0,0.3);

$header-width: 980px;

/*for js use*/
:export {
  candidate0: #69CEB2;
  candidate1: #76A2D9;
  candidate2: #AB94D0;
  candidate3: #EC7778;
  candidate4: #EAAE72;
  candidate5: #858585;
}
