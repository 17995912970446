.notifications{
    position:fixed;
    z-index:100000;
    top:45px;right:0px;
}

.notification{

    padding:8px;
    font-size:14px;

    .close{float:right;margin-left:6px;font-weight:bold;}
    
    &.success{background: #cdffcc; color: green}
    &.notify{background: #ff9;color: #757900}
    &.error{background: #fae9ea; color: #d73039}
}