@import '../../style/style-constants.module.scss';

$line-height: 16px;

.checkbox-component {
    display: inline-flex;
    align-items: center;
    line-height: $line-height;

    cursor: pointer;
    color: $text-color;

    .checkbox-icon {
        margin-right: 6px;
        width: 16px;
        height: $line-height;

        &.selected-checkbox-icon use{fill: $amobee-blue !important}

    }

    &:hover{
        color: $amobee-blue;
    }
}

.disabled.checkbox-component,
.disabled .checkbox-component{
    pointer-events: none;
    cursor: default;
    .checkbox-icon{color: #495055;}
}
