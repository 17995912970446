@import './../../style/style-constants.module.scss';


.geo{

	path{
		transition: all 0.3s ease-in-out;
		&:hover{
			stroke: white;
    		stroke-width: 3px;
		}
	}
}
