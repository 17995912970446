@import "./../fonts/roboto.css";
@import "./style-constants.module.scss";

//general style
body{
	margin: 0px;
	padding: 0px;
	font:14px Roboto;
	color: $text-color;
}

hr{
	width:99%;
	border-color: $line-light-color;
	border-bottom:0px;
	margin: 10px 0px;
}


.page-center{
	width: $header-width;
	margin: 0 auto;
}

.page-grid{
	display: grid;
	grid-template-columns: 490px 490px;

	.grid-item{
		vertical-align:top;
		&>.info{margin-bottom: 20px;}

		&:nth-child(odd){
			border-right: 1px solid $border-color;
		}

		&:nth-child(even){
			margin-left:35px;
		}
	}
}

.title{
	margin-top: 16px;
	color: #323640;
    font-size: 18px;
    font-weight: 700;
}

.__react_component_tooltip{
	padding: 4px 8px !important;
	border-radius: 4px !important;
}

.info{
	color: rgba(0,0,0,0.5);
	font-size:12px;
	margin: 4px 0px;
}

.labels{ //candidate names + color rect
	margin-bottom:16px;
	min-height: 48px;
}

.rect-with-name{
	display: inline-block;
	min-width: 150px;
	margin-bottom: 8px;
}

.rect{
	margin-left:6px;
	margin-right:3px;
	display:inline-block;
	height: 10px;
	width: 10px;
	border-radius: 2px;
}


.landscape{

	.page-grid{
		display: inline-grid;
		margin-left: calc(#{$header-width/2} - 50vw);
		grid-template-columns:
		470px 470px 520px 380px;
	}

	.page-grid-hr{
		display:none;
	}

	.grid-item{
		border-color: transparent !important;
		margin-left:35px;

		&:first-child{
			margin-left:15px;
		}
	}

	#map{
		position: relative;
    	left: -10px;
    	width: 425px;
    	height: 276px;
	}
}


//helper classes
.pointer{cursor:pointer;}
.inline-block{display:inline-block}
.right{float:right}
.hide{display:none;}
