@import './../../style/style-constants.module.scss';

.top-bar{
	height: 45px;
	margin-bottom:22px;
	background: #353742;

	.top-bar-inner{
		line-height: 45px;
	}

	.top-bar-title{
		color: white;
		font-size:22px;
		font-weight:700;
	}

	.powered-by{
		float: right;
		color: lightgrey;
	}
}

.top-title{
	.title{
		font-size:20px;
		margin-bottom: 7px;
	}
	padding-bottom:8px;
	border-bottom: 1px solid #{$border-color};
}

.settings{

	position:absolute;
	top: 12px;
	right: 12px;
	color: white;

	.icon{
		transition: all 0.5s ease-in-out;
	}

	.landscape{
		transform: rotate(90deg);
	}
}
