.widget-error{
	display:flex;
	align-items:center;
	min-height:180px;
	text-align:center;
	color: #E02A2B;

	.inner-error{
		margin: 0 auto;
	}
	
	.error-title{
		margin: 16px; 
		font-size: 16px;
		font-weight: 700;
	}
}