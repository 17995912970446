.login-not-authorized, .login-loading{
	width:100vw;
	height:100vh;
	background-image: url('/images/back.jpg');
	background-size: 100% 100%;
	display: flex;
  	align-items: center;
    justify-content: center;

	.box{
		width: 340px;
		height: 200px;
  		border-radius: 3px;
  		border: 1px solid #ddd;

		background: white;
		padding: 20px;
		text-align:center;

		.welcome{
			margin: 30px 0px;
			color: black;
		}
		
		.logo{width:200px;}
	}
}