@import './../../style/style-constants.module.scss';

.audience{

	.skew-rect{
		display: inline-block;
		width: 10px;
		height: 10px;
		border-radius: 2px;
		margin: 0px 1px;

		&.skew0{background-color: #3A7FD4;}
		&.skew1{background-color: #76A2D9;}
		&.skew2{background-color: #A6C8E9;}
		&.skew3{background-color: #EAD6D6;}
		&.skew4{background-color: #F9ACAC;}
		&.skew5{background-color: #EC7778;}
		&.skew6{background-color: #D43A3A;}
		&.no-data{background-color: #CFCFCF;}

		&.medium{
			width:24px;
			height: 24px;
		}

		&.double{width:50px;}
	}

	.grid{
		display: grid;
		grid-template-columns: 100px 60px 60px 60px 60px 60px 60px;

		&>div:not(.first-item){
			text-align: center;
		}
	}

	.gender-icon{
		color: #CFCFCF;
		margin: 4px;
	}

	.race{
		text-transform: capitalize;
	}
}
