@import './../../style/style-constants.module.scss';

.dropdown{
    box-shadow: 0 0 0 1px $line-light-color, 0 8px 16px 0 $line-dark-color;

    background-color: $white;

    .dropdown-item{
        padding:12px 6px;
        &:hover{
            background-color: $amobee-blue;
            *{color: $white};
            .selected-checkbox-icon use{
                fill: $white !important;
            }
        }
    }
}
