@import './../../style/style-constants.module.scss';

.selection{
	padding: 16px 0px 10px 0px;
	margin-bottom: 10px;
	border-bottom: 1px solid $border-color;

	.title{font-size: 14px;}

	.chevron{
		margin-left: 25px;
		color: rgba(0,0,0,0.5);
	}

	.candidates-selection, .datasets-selection{
		margin-top:6px;
		padding: 0px 14px;
		width: calc(#{$header-width} - 28px); //minus padding
		z-index: 2;
		max-height: 0px;
		overflow: hidden;
		opacity:0;
		transition: all 0.5s ease-in-out;

		&.open{
			max-height: 400px;
			opacity:1;
			padding: 14px;
		}

		.checkbox-wrap{
			margin:10px;
			width: 168px;
			text-align:left;
			display: inline-block;
		}
	}

	.datasets-selection{
		position:absolute;
		margin-top:-10px;
		margin-left: 15px;

		&.open{
			max-height: 330px;
		}

		.react-calendar__navigation__prev2-button,
		.react-calendar__navigation__next2-button{
			display: none;
		}

		.calendar-title{
			position: absolute;
    		right: 42px;
    		text-align:center;
    		padding-top:4px;
		}

		.react-calendar{
			border:0px;
			float:right;
			width: 250px;
			padding: 4px;
			padding-top: 32px;

			*{font-weight: 700}

			abbr[title] {
				font-weight: 300;
				text-decoration: none;
				color: $line-dark-color;
				font-size: 12px;
			}

			.react-calendar__navigation__label{
				color: black;
			}

			.react-calendar__tile--active{
				color: #fff !important;
				background-color: $amobee-blue;
				border-radius: 3px;
			}

			button,
			.react-calendar__month-view__days__day--weekend,
			.react-calendar__month-view__days__day--neighboringMonth{
				color: $text-light-color;
			}

			.react-calendar__navigation button[disabled],
			.react-calendar__tile:disabled{
				background-color: inherit;
				opacity: 0.3;
			}

			.react-calendar__navigation button:enabled:hover{
				background-color: inherit;
			}
		}
	}
}
