@import './../../style/style-constants.module.scss';

.engagement{
	display:inline-block;
	width:120px;
	text-align:left;
	margin-top: 8px;

	.bar{
		display:inline-block;
		height:13px;
		border-radius:2px;
		background-color: $amobee-blue;
		margin-right:6px;
		width: 1px;
		transition: all 0.5s ease-in-out;
	}
	.label{
		display:inline-block;
		color: $amobee-blue;
		line-height:16px;
		vertical-align:top;
		margin-top: -1px;
		font-size:12px;
	}
}

.engagement-empty{
	height: 46px;
}
